import moment from "moment";
import { downloadFile } from "../../toolympus/api/core";
import { useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { useFormats } from "../../toolympus/components/schemed";
import { FieldType, Schema } from "../../toolympus/hooks/useSchema";

export interface Subscriber {
    _id: string;
    email: string;
    code: string;
    created_datetime?: string;
}

const SubscriberSchema: Schema = {
    email: { label: "E-mail" },
    code: { label: "Код" },
    created_datetime: { label: "Подписался", type: FieldType.datetime },
}

export const SubscribersApiPath = "/api/subscription";

export const useSubscribers = () => {
    const data = useCrudUnpagedList<Subscriber>(SubscribersApiPath, {
        defaultView: "",
        filterBy: s => s.email,
    });

    const { formatDate } = useFormats();

    const exportSubscribers = () => {
        downloadFile(`${SubscribersApiPath}/export`, `YIMA_subscribers_${formatDate(moment())}.xlsx`);
    }

    return {
        ...data,
        schema: SubscriberSchema,
        exportSubscribers,
    }
}
