import React from 'react';
import styled from '@emotion/styled';
import { Dialog, SearchField } from '../../toolympus/components/primitives';
import { CommitteePeopleData } from './useCommitteePeople';
import { Typography } from '@mui/material';
import { Star } from '@mui/icons-material';
import { Person } from '../People/usePersonList';

const PersonTileWrapper = styled.div<{ isSelected?: boolean }>`
    margin: 0.5rem;
    border: 3px solid ${props => props.isSelected ? props.theme.palette.primary.main : "transparent"};
    padding: 3px;
    & img {
        width: 205px;
        height: 150px;
        object-position: top center;
        object-fit: cover;
    }
`;

export const PeopleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
`;



interface PersonProps {
    person: Person;
    isSelected?: boolean;
    isChair?: boolean;
    toggleSelection?: () => void;
}

export const PersonTile = ({ person, isSelected, isChair, toggleSelection }: PersonProps) => (
    <PersonTileWrapper
        isSelected={!!isSelected}
        onClick={toggleSelection}>
        <img src={person.photo_url || "/img/nophoto.png"} alt={person.name} />
        <Typography>{isChair && <Star fontSize="inherit" color="primary" />} {person.name}</Typography>
    </PersonTileWrapper>
);


interface Props {
    data: CommitteePeopleData;
}

export const CommitteePeopleSelection = ({ data }: Props) => {
    return (
        <Dialog
            dialogTitle="Люди"
            titleActions={(
                <SearchField
                    {...data.filter}
                    autoFocus
                    noButton
                    />
                )}
            maxWidth="lg"
            {...data.dialog}>
            <PeopleWrapper>
                {data.allPeople.map(p => {
                    const isSelected = data.isSelected(p._id);
                    return (
                    <PersonTile
                        key={p._id}
                        person={p}
                        isSelected={!!isSelected}
                        isChair={isSelected && isSelected.is_chair}
                        toggleSelection={() => data.selectPerson(p._id, !(isSelected && isSelected.is_chair), !!(isSelected && !isSelected.is_chair))}
                        />
                )})}
            </PeopleWrapper>
        </Dialog>
    );
}
