import React from 'react';
import { useSettings } from '../toolympus/api/useSettings';
import { Form, FormGrid, OccupyFreeSpace, SaveButton } from '../toolympus/components/primitives';
import { useSaveable } from '../toolympus/components/primitives/useSaveable';

interface Settings {
    menu: any[];
}

const useSettingsEdit = () => {
    const data = useSettings("/api/settings", { menu: [] } as Settings);
    return {
        ...data,
    }
}

export const SettingsPage = () => {
    const data = useSettingsEdit();
    useSaveable(data);

    return (
        <Form
            title="Настройки"
            headerItems={<>
                <OccupyFreeSpace />
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </>}
            >
            
            <FormGrid columns="1fr">
                {data.withControls.controls([
                    ["menu"],
                ])}
            </FormGrid>
        </Form>
    );
}
