import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { DeleteOutlined, FileCopyOutlined } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { Form, FormGrid, OccupyFreeSpace, SaveButton } from '../../toolympus/components/primitives';
import { YimaEditor } from '../YimaEditor';
import { usePersonEdit } from './usePersonEdit';
import { MediaLibPicker, useMediaLibContext } from '../../toolympus/components/medialib';
import { DefaultLocaleSwitch } from '../../toolympus/components/LocalizedRecords';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';

export const PersonPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const data = usePersonEdit(id);
    useSaveable(data);
    const { controls } = useItemWithControls(data, { schema: data.schema });
    const medialib = useMediaLibContext();

    const remove = useActionWithConfirmation(() => data.remove(), { title: "Удалить человека", confirmationLabel: "Удалить" });

    return (
        <Form
            title="Люди"
            headerItems={<>
                <OccupyFreeSpace />
                <DefaultLocaleSwitch locale={data.locale} setLocale={data.setLocale} />
                <IconButton size="small" onClick={() => data.copyItem().then(n => history.push(`/person/${n._id}`))}>
                    <FileCopyOutlined />
                </IconButton>
                <IconButton size="small" color="secondary" onClick={() => remove.run()}>
                    <DeleteOutlined />
                </IconButton>
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </>}
            >
            <FormGrid columns="1fr">
                {controls([
                    ["name"],
                    ])}
            </FormGrid>

            <FormGrid style={{ alignItems: "start" }}>
                {controls([
                    ["description_brief", { autoRows: true, wrapperStyle: { gridColumn: "span 2" } }],
                    ])}

                <MediaLibPicker
                    selectFile={mf => data.update({ photo_url: mf ? medialib.getFilepath(mf) : null })}
                    selectedUrl={data.data.photo_url}
                    closeOnSelect
                    label="Фотография"
                    />                    
            </FormGrid>

            <Typography variant="caption" color="textSecondary" style={{ marginTop: "1rem" }}>Описание</Typography>
            <YimaEditor
                key={`about_${data.locale}_${data.data._id}`}
                content={data.data.description}
                update={v => data.update({ description: v })}
                />

        </Form>
    );
}
