import React from 'react';
import { useHistory } from 'react-router-dom';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { SimpleDialog, FormGrid, SimpleList, SlugEditControl } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { CreatePage, Page, usePageList } from './usePageList';

export const PageList = () => {
    const data = usePageList();
    const history = useHistory();

    return (<>
        <SimpleList
            title="Страницы"
            data={data}
            schema={data.schema}
            getLinkValue={n => `/page/${n._id}`}
            fields={[
                ["title"],
                ["slug"],
                ["lang"],
            ]}
            linkFields={["title", "slug"]}
            />
        <CreatePagePopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`/page/${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

interface CreatePageProps extends NewItemProps<CreatePage, Page> {
    schema: Schema;
}

const CreatePagePopup = (props: CreatePageProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreatePage, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle="Создать страницу"
            save={() => save()}
            submitOnModEnter
            noFullscreen
            >
            <FormGrid columns="1fr">
                {controls([
                    ["title"],
                    ["slug", { control: SlugEditControl, slugSource: item?.title || "" }],
                    ["lang", { autoComplete: true }],
                ])}
            </FormGrid>
        </SimpleDialog>);
}
