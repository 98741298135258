import React from 'react';
import { useHistory } from 'react-router-dom';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { SimpleDialog, FormGrid, SimpleList, SlugEditControl } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { CreateEvent, Event, useEventsList } from './useEventsList';

export const EventsList = () => {
    const data = useEventsList();
    const history = useHistory();

    return (<>
        <SimpleList
            title="Мероприятия"
            data={data}
            schema={data.schema}
            getLinkValue={n => `/event/${n._id}`}
            fields={[
                ["title"],
                ["slug"],
                ["lang"],
                ["event_datetime", { utcToLocal: true }],
                ["published_datetime", { utcToLocal: true }],
            ]}
            linkFields={["title", "slug"]}
            />
        <CreateEventPopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`/event/${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

interface CreateEventProps extends NewItemProps<CreateEvent, Event> {
    schema: Schema;
}

const CreateEventPopup = (props: CreateEventProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreateEvent, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle="Создать мероприятие"
            save={() => save()}
            noFullscreen
            submitOnModEnter
            >
            <FormGrid columns="1fr">
                {controls([
                    ["title", { autoRows: true }],
                    ["slug", { control: SlugEditControl, slugSource: item?.title || "" }],
                    ["lang", { autoComplete: true }],
                ])}
            </FormGrid>
        </SimpleDialog>);
}