import { IconButton, Typography } from '@mui/material';
import { DeleteOutlined, FileCopyOutlined } from '@mui/icons-material';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { Form, FormGrid, OccupyFreeSpace, SaveButton, SlugEditControl } from '../../toolympus/components/primitives';
import { GoToSite } from '../Common/GoToSite';
import { usePublishOrPublished } from '../Common/PublishOrPublishedDate';
import { TranslationRecords } from '../Common/TranslationRecords';
import { YimaEditor } from '../YimaEditor';
import { useNewsItem } from './useNewsItem';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';

export const NewsPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const data = useNewsItem(id);
    useSaveable(data);
    const { controls } = useItemWithControls(data, { schema: data.schema });
    const PublishedControl = usePublishOrPublished(() => data.publish(), [data.data.published_datetime]);

    const remove = useActionWithConfirmation(() => data.remove(), { title: "Удалить новость", confirmationLabel: "Удалить" });

    return (
        <Form
            title="Новости"
            headerItems={<>
                <OccupyFreeSpace />
                <GoToSite entity="news" id={data.data.slug} lang={data.data.lang} />
                <IconButton size="small" onClick={() => data.copyItem().then(n => history.push(`/news/${n._id}`))}>
                    <FileCopyOutlined />
                </IconButton>
                <IconButton size="small" color="secondary" onClick={() => remove.run()}>
                    <DeleteOutlined />
                </IconButton>
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </>}
            >
            <FormGrid columns="1fr">
                {controls([["title", { autoRows: true }]])}
            </FormGrid>

            <FormGrid style={{ alignItems: "end" }}>
                {controls([
                    ["slug", { control: SlugEditControl, slugSource: data.data?.title || "", wrapperStyle: { gridColumn: "span 2" } }],
                    ["lang"],
                    ["categories", { wrapperStyle: { gridColumn: "span 2" }}]
                    ])}
                <TranslationRecords {...data.translations} getItemLink={t => `/news/${t._id}`} />
                
                {controls([
                    ["news_datetime", { utcToLocal: true }],
                    ["display_datetime"],
                    ["published_datetime", { utcToLocal: true, control: PublishedControl }],
                    ])}
                    
            </FormGrid>

            <Typography variant="caption" color="textSecondary" style={{ marginTop: "1rem" }}>Содержимое</Typography>
            <YimaEditor
                key={data.data._id}
                content={data.data.content}
                update={v => data.update({ content: v })}
                />
        </Form>
    );
}
