import React from 'react';
import { useHistory } from 'react-router-dom';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { SimpleDialog, FormGrid, SimpleList } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { CreateProject, Project, useProjectList } from './useProjectList';

export const ProjectList = () => {
    const data = useProjectList();
    const history = useHistory();

    return (<>
        <SimpleList
            title="Проекты"
            data={data}
            schema={data.schema}
            getLinkValue={n => `/project/${n._id}`}
            fields={[
                ["title"],
                ["slug"],
                ["committee"],
                ["description_brief"],
            ]}
            linkFields={["title","slug","description_brief"]}
            />
        <CreateProjectPopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`/project/${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

interface CreateProjectProps extends NewItemProps<CreateProject, Project> {
    schema: Schema;
}

const CreateProjectPopup = (props: CreateProjectProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreateProject, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle="Создать проект"
            save={() => save()}
            noFullscreen
            >
            <FormGrid columns="1fr">
                {controls([
                    ["title"],
                ])}
            </FormGrid>
        </SimpleDialog>);
}
