import { IconButton } from '@mui/material';
import { CallMade } from '@mui/icons-material';
import React from 'react';
import { Tooltip } from '../../toolympus/components/primitives';

interface Props {
    entity: string;
    id?: string;
    lang?: string;
    idOptional?: boolean;
}

export const GoToSite = (props: Props) => {
    const link = `${process.env.REACT_APP_SITE_URL}/goto?entity=${props.entity}&id=${props.id || ""}&lang=${props.lang || ""}`;

    if(!props.entity
        || (!props.id && !props.idOptional)) {
        return null;
    }

    return (
        <Tooltip text="Показать на сайте">    
            <IconButton href={link} target="_blank" rel="noreferrer noopener" size="small">
                <CallMade />
            </IconButton>
        </Tooltip>
    );
}
