import React, { useState } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import { DeleteOutlined, FileCopyOutlined } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { ActionRow, Form, FormGrid, LoadingIndicator, OccupyFreeSpace, SaveButton, SlugEditControl, TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives';
import { YimaEditor } from '../YimaEditor';
import { useCommitteeEdit } from './useCommitteeEdit';
import { MediaLibPicker, useMediaLibContext } from '../../toolympus/components/medialib';
import { DefaultLocaleSwitch } from '../../toolympus/components/LocalizedRecords';
import { TableForFields } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { CommitteePeopleSelection, PeopleWrapper, PersonTile } from './CommitteePeopleSelection';
import { GoToSite } from '../Common/GoToSite';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { usePublishOrPublished } from '../Common/PublishOrPublishedDate';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';

export const CommitteePage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const data = useCommitteeEdit(id);
    useSaveable(data);
    const { controls } = useItemWithControls(data, { schema: data.schema });
    const medialib = useMediaLibContext();

    const [contentRerenderer, setContentRerenderer] = useState<number>(0);

    const tabs = useTabsState([
        ["info", "Информация"],
        ["content", "Содержимое страницы"],
        ["people", "Люди"],
        ["projects", "Проекты"],
        ["news", "Новости"],
    ], "info", "tab");

    const PublishedControl = usePublishOrPublished(v => data.save({ published_datetime: v }), [data.data.published_datetime]);

    const remove = useActionWithConfirmation(() => data.remove(), { title: "Удалить комитет", confirmationLabel: "Удалить" });

    return (
        <Form
            title="Комитет"
            headerItems={<>
                <OccupyFreeSpace />
                <GoToSite entity="committee" id={data.data.slug} lang={data.locale} />
                <DefaultLocaleSwitch locale={data.locale} setLocale={data.setLocale} />
                <IconButton size="small" onClick={() => data.copyItem().then(n => history.push(`/committee/${n._id}`))}>
                    <FileCopyOutlined />
                </IconButton>
                <IconButton size="small" color="secondary" onClick={() => remove.run()}>
                    <DeleteOutlined />
                </IconButton>
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </>}
            >
            <FormGrid columns="2fr 1fr" alignItems="flex-end">
                {controls([
                  ["title"],
                  ["published_datetime", { utcToLocal: true, control: PublishedControl }],
                  ])}
            </FormGrid>

            <TabsHeader state={tabs} scrollable></TabsHeader>

            <TabPanel state={tabs.forTab("info")}>
                <FormGrid style={{ alignItems: "start" }}>
                    {controls([
                        ["slug", { control: SlugEditControl, slugSource: data.data?.title || "" }],
                        ["sortorder"],
                        ])}

                    <MediaLibPicker
                        selectFile={mf => data.update({ logo_url: mf ? medialib.getFilepath(mf) : null })}
                        selectedUrl={data.data.logo_url}
                        closeOnSelect
                        label="Логотип"
                        />                    
                </FormGrid>

                <Typography variant="caption" color="textSecondary" style={{ marginTop: "1rem" }}>О комитете</Typography>
                <YimaEditor
                    key={`about_${data.locale}_${data.data._id}`}
                    content={data.data.about}
                    update={v => data.update({ about: v })}
                    />

                <Typography variant="caption" color="textSecondary" style={{ marginTop: "1rem" }}>Миссия</Typography>
                <YimaEditor
                    key={`mission_${data.locale}_${data.data._id}`}
                    content={data.data.mission}
                    update={v => data.update({ mission: v })}
                    />
            </TabPanel>

            <TabPanel state={tabs.forTab("content")}>
                <ActionRow firstItemNoMargin>
                    <div>
                        <Typography variant="caption" component="p" color="textSecondary">
                          По умолчанию страница комитета включает следующие блоки: о комитете и миссия, председатели, проекты, новости, члены, кнопка "присоединиться".
                        </Typography>

                        <Typography variant="caption" component="p" color="textSecondary">
                          Если вы хотите указать другой контент, воспользуйтесь редактором ниже.
                        </Typography>
                    </div>

                    <OccupyFreeSpace />

                    <Button size="small" color="primary" onClick={() => { data.setDefaultContent(); setContentRerenderer(x => x+1)}}>добавить содержимое по-умолчанию</Button>
                    <Button size="small" color="secondary" onClick={() => { data.clearContent(); setContentRerenderer(x => x+1)}}>очистить содержимое</Button>
                </ActionRow>

                <YimaEditor
                    key={`content_${data.locale}_${data.data._id}_${contentRerenderer}`}
                    content={data.data.content}
                    update={v => data.update({ content: v })}
                    />
            </TabPanel>


            <TabPanel state={tabs.forTab("projects")}>
                {data.projects.isLoading && <LoadingIndicator />}
                {!data.projects.isLoading && <TableForFields
                    data={data.projects.data}
                    fields={[
                        ["title"],
                        ["description_brief"],
                        ["published_datetime", { utcToLocal: true }],
                    ]}
                    schema={{
                        title: { label: "Название" },
                        description_brief: { label: "Описание" },
                        published_datetime: { type: FieldType.datetime, label: "Опубликован" },
                    }}
                    fieldLink={f => f === "title" ? p => `/project/${p._id}` : null}
                    />}
            </TabPanel>

            <TabPanel state={tabs.forTab("news")}>
                {data.news.isLoading && <LoadingIndicator />}
                {!data.news.isLoading && <TableForFields
                    data={data.news.data}
                    fields={[
                        ["title"],
                        ["slug"],
                        ["published_datetime", { utcToLocal: true }],
                    ]}
                    schema={{
                        title: { label: "Title" },
                        slug: { label: "Slug" },
                        published_datetime: { type: FieldType.datetime, label: "Опубликован" },
                    }}
                    fieldLink={f => f === "title" ? p => `/news/${p._id}` : null}
                    />}
            </TabPanel>

            <TabPanel state={tabs.forTab("people")}>
                {data.people.selectedPeople.length === 0 &&
                    <Button color="primary" onClick={() => data.people.dialog.open()}>lдобавить людей в комитет</Button>}
                
                
                <PeopleWrapper onClick={() => data.people.dialog.open()} style={{ cursor: "pointer"}}>
                    {data.people.selectedPeople.map(p => (
                        <PersonTile
                            key={p._id}
                            person={p}
                            isChair={!!p.is_chair}
                            />
                    ))}
                </PeopleWrapper>
                <CommitteePeopleSelection data={data.people} />
            </TabPanel>
        </Form>
    );
}
