import { IconButton } from '@mui/material';
import { DeleteOutlined, FileCopyOutlined } from '@mui/icons-material';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { Form, FormGrid, OccupyFreeSpace, SaveButton, SlugEditControl, TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives';
import { GoToSite } from '../Common/GoToSite';
import { usePublishOrPublished } from '../Common/PublishOrPublishedDate';
import { TranslationRecords } from '../Common/TranslationRecords';
import { YimaEditor } from '../YimaEditor';
import { useEditEvent } from './useEditEvent';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { useMediaLibContext, MediaLibPicker } from '../../toolympus/components/medialib';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';

export const EventPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const data = useEditEvent(id);
    useSaveable(data);
    const { controls } = useItemWithControls(data, { schema: data.schema });
    const medialib = useMediaLibContext();

    const tabs = useTabsState([
        ["details", "Детали"],
        ["content", "Содержимое"],
    ], "details", "tab");

    const PublishedControl = usePublishOrPublished(() => data.publish(), [data.data.published_datetime]);

    const remove = useActionWithConfirmation(() => data.remove(), { title: "Удалить мероприятие", confirmationLabel: "Удалить" });

    return (
        <Form
            title="Мероприятие"
            headerItems={<>
                <OccupyFreeSpace />
                <GoToSite entity="event" id={data.data.slug} lang={data.data.lang} />
                <IconButton size="small" onClick={() => data.copyItem().then(n => history.push(`/event/${n._id}`))}>
                    <FileCopyOutlined />
                </IconButton>
                <IconButton size="small" color="secondary" onClick={() => remove.run()}>
                    <DeleteOutlined />
                </IconButton>
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </>}
            >
            <FormGrid columns="1fr">
                {controls([["title", { autoRows: true }]])}
            </FormGrid>

            <FormGrid style={{ alignItems: "end" }}>
                {controls([
                    ["slug", { control: SlugEditControl, slugSource: data.data?.title || "", wrapperStyle: { gridColumn: "span 2" } }],
                    ["lang"],
                    ["variant", { wrapperStyle: { gridColumn: "span 2" }}]
                    ])}
                <TranslationRecords {...data.translations} getItemLink={t => `/event/${t._id}`} />
                
                {controls([
                    ["event_datetime", { utcToLocal: true }],
                    ["display_datetime"],
                    ["published_datetime", { utcToLocal: true, control: PublishedControl }],
                    ])}
                    
            </FormGrid>

            <TabsHeader state={tabs} />

            <TabPanel state={tabs.forTab("details")}>
                <FormGrid columns="1fr 1fr">
                    <MediaLibPicker
                        selectFile={mf => data.update({ cover_url: mf ? medialib.getFilepath(mf) : null })}
                        selectedUrl={data.data.cover_url}
                        closeOnSelect
                        label="Photo"
                        />

                    <MediaLibPicker
                        selectFile={mf => data.update({ pdf_url: mf ? medialib.getFilepath(mf) : null })}
                        selectedUrl={data.data.pdf_url}
                        closeOnSelect
                        label="PDF"
                        showFilenameInsteadOfImage
                        />

                </FormGrid>
                <FormGrid columns="1fr 3fr 4fr">
                    {controls([
                        ["location_type"],
                        ["location"],
                        ["video_url"],
                        ])}
                </FormGrid>
            </TabPanel>

            <TabPanel state={tabs.forTab("content")}>
                <YimaEditor
                    key={data.data._id}
                    content={data.data.content}
                    update={v => data.update({ content: v })}
                    />
            </TabPanel>
        </Form>
    );
}
