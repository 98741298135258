import React from 'react';
import { useHistory } from 'react-router-dom';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { SimpleDialog, FormGrid, SimpleList } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { CreatePerson, Person, usePersonList } from './usePersonList';

export const PersonList = () => {
    const data = usePersonList();
    const history = useHistory();

    return (<>
        <SimpleList
            title="Люди"
            data={data}
            schema={data.schema}
            getLinkValue={n => `/person/${n._id}`}
            fields={[
                ["name"],
                ["description_brief"],
            ]}
            linkFields={["name","description_brief"]}
            />
        <CreatePersonPopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`/person/${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

interface CreatePersonProps extends NewItemProps<CreatePerson, Person> {
    schema: Schema;
}

const CreatePersonPopup = (props: CreatePersonProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreatePerson, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle="Создать человека 😐"
            save={() => save()}
            noFullscreen
            >
            <FormGrid columns="1fr">
                {controls([
                    ["name"],
                ])}
            </FormGrid>
        </SimpleDialog>);
}
